.auth-nav {
    .right {
        transition: all 0.3s ease-in-out;
        transform: translate(0, 0);
        &.mobile-hidden {
            transform: translate(-100%, 0);
        }
    }
    .svg-full {
        svg rect {
            transition: transform .3s ease, fill .3s ease;
            transform-origin: left center;
        }
        &.mobile-nav-show {
            svg rect:first-child {
                transform: scalex(.4);
            }
            svg rect:last-child {
                transform: scalex(2.5);
            }
        }
    }
}

@media (min-width: $lg) {
    .auth-nav {
        .right {
            &.mobile-hidden {
                transform: translate(0, 0);
            }
        }
    }
}
