@tailwind base;

@tailwind components;

@tailwind utilities;

@import './lib/nprogress';

@import './_variables';
@import './components/authnav';

@font-face {
    font-family: 'DM Serif Display';
    src: local('DM Serif Display'), url(./fonts/DMSerifDisplay-Regular.ttf) format('ttf');
  }

input:invalid {
    box-shadow: none;
}

@layer components {
    .next-link {
        @apply flex justify-between items-center gap-x-12 rounded-lg px-4 py-2 uppercase text-sm outline-none ease-linear transition-all duration-150 text-primary border-2  mx-auto my-16 cursor-pointer ;
    }
}

.parse-quill{
    h1{
        font-size: 20px;
        padding: 5px;
        font-weight: 500;
    }
    h2{
        font-size: 18px;
        padding: 5px;
        font-weight: 500;
    }
    h3,h4,h5{
        font-size: 16px;
        padding: 5px;
    }

    ul{
        padding: 20px;
        list-style: disc;
    }
    ol{
        padding: 20px;
        list-style: decimal;
    }
}
.ql-editor{
  height: 300px !important;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}
